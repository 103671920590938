'use client';
import React from "react";
import styles from "./cookieconsent.module.scss";

export interface Props {
}

export default function CookieConsent({  }: Props) {
    return (
        <script id="CookieDeclaration" src="https://consent.cookiebot.com/e4c75e38-501e-4c40-ba9b-cf4095377685/cd.js" type="text/javascript" async></script>
    );
}
